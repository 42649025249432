import React, { useState } from "react";
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from "gatsby"
import { Container, Row, Col, Card } from "react-bootstrap"
import "./Gallery.scss"
import { AltSiteName, SaleURL, LetURL } from "../../utils";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import parse from 'html-react-parser';
import GetCTA from "../../getcta";
import AreaRightBlock from "../../PropertyDetails/RightBlock/AreaRightBlock";
const Gallery = (props) => {
    const [toggler, setToggler] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const openLighboxGallery = (e, ind) => {
        e.preventDefault();
        setPhotoIndex(ind);
        setIsOpen(true);
    }
    var GalleryImages = props.galleryimages && props.galleryimages.map(img => img.Areaguides_Images.url);
    return (
        <React.Fragment>
            <section className="gallery-section">
                
                    <Container>
                        <Row>
                            <Col lg={8}>
                                <div className="gallery-wrapper">
                                    {props.galleryimages && props.galleryimages.slice(0, 1).map((image) => {
                                    return<>                                        
                                    <div className="gallery-img img-zoom">
                                        <a href="javascript:;" onClick={(e) => openLighboxGallery(e, 0)} className="img-link">
                                            <picture>
                                                <source media="(min-width:1600px)" srcSet={image.Areaguides_Images.url} />
                                                <source media="(min-width:992px)" srcSet={image.Areaguides_Images.url} />
                                                <source media="(min-width:768px)" srcSet={image.Areaguides_Images.url} />
                                                <img src={image.Areaguides_Images.url} alt={image.Areaguides_Images.alternativeText?image.Areaguides_Images.alternativeText:props.title+'- Gallery - Image -1'+AltSiteName} />
                                            </picture>
                                        </a>
                                        <a onClick={(e) => openLighboxGallery(e, 0)} href="javascript:;" className="total">
                                            <i className="icon-gallery"></i>
                                            <span>{props.galleryimages.length} </span>photos
                                        </a>

                                    </div>
                                   
                                    </>
                                    })}
                                     {isOpen && (
                                        <Lightbox
                                            mainSrc={GalleryImages[photoIndex]}
                                            nextSrc={GalleryImages[(photoIndex + 1) % GalleryImages.length]}
                                            prevSrc={GalleryImages[(photoIndex + GalleryImages.length - 1) % GalleryImages.length]}
                                            onCloseRequest={() => setIsOpen(false)}
                                            onMovePrevRequest={() =>
                                                setPhotoIndex((photoIndex + GalleryImages.length - 1) % GalleryImages.length)
                                            }
                                            onMoveNextRequest={() =>
                                                setPhotoIndex((photoIndex + 1) % GalleryImages.length)
                                            }
                                        />
                                    )}
                                    <div className="gallery-bottom">
                                        {props.boximages && props.boximages.map((image, index) => {
                                        return<>
                                        <Link  className="img-link">
                                            <div className="gallery-img img-zoom">
                                                <picture>
                                                    <source media="(min-width:768px)" srcSet={image.Areaguide_Box_Image.url} />
                                                    <img src={image.Areaguide_Box_Image.url} alt={image.Areaguide_Box_Image.alternativeText?image.Areaguide_Box_Image.alternativeText:props.title+'- Gallery Bottom Image-'+index+''+AltSiteName}  />
                                                </picture>
                                            </div>
                                        </Link>
                                        </>
                                        })}
                                    </div>
                                </div>
                                
                                <div className="static-page">
                                    <div className="static-full-content">
                                        {parse(props.belowcontent)}  
                                    </div>
                                </div>
                                {props.ctas && props.ctas.length > 0 &&

                                <div className="bottom-ctas">                              
                                    <div class="btn-wrapper">
                                    <Link class="btn btn-primary" to={`${SaleURL}/${props.title.toLowerCase()}`}>Find a property</Link>
                                    <Link class="btn" href="/property-valuation">Book your appraisal</Link>
                                    {/* {props.ctas && props.ctas.length > 0 && props.ctas.map((cta,index) => {
                                    return<>
                                        {index === 0 &&
                                        <>
                                        {cta.CTA_Custom_Link ?
                                        <Link to={cta.CTA_Custom_Link} className="btn btn-primary">{cta.CTA_Label}</Link>
                                        :
                                        <GetCTA Label={cta.CTA_Label} class="btn btn-primary" link={cta.CTA_Link && cta.CTA_Link.Primary_URL}/>
                                        }
                                        </>
                                        }
                                        {index === 1 &&
                                        <>
                                        {cta.CTA_Custom_Link ?
                                        <Link to={cta.CTA_Custom_Link} className="btn">{cta.CTA_Label}</Link>
                                        :
                                        <GetCTA Label={cta.CTA_Label} class="btn" link={cta.CTA_Link && cta.CTA_Link.Primary_URL}/>
                                        }
                                        </>
                                        }
                                    </>
                                    })} */}
                                    </div>                                
                                </div>
                                }
                                <div className="mobile-block-contact d-block d-md-none d-lg-none">
                                <AreaRightBlock staff={props.staff}/>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                                    
            </section>
        </React.Fragment>
    )
}
export default Gallery
