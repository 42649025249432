import React, {useState,useEffect} from "react";
import {graphql } from 'gatsby'
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss";
import "../scss/global.scss";
import "../scss/grunticon.scss";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import Intro from '../Components/CarrierLanding/Intro';
import DetailsInfo from '../Components/OfficeDetails/DetailsInfo/DetailsInfo'
import Gallery from '../Components/OfficeDetails/Gallery/Gallery'
import DetailMap from "../Components/detail-map";
import FeaturedProperty from "../Components/PropertyDetails/PropertyList/FeaturedProperty";
import ElfsightWidget from "../Components/OfficeDetails/ElfsightWidget/ElfsightWidget";
import ContactModule from "../Components/CarrierLanding/Connect/ContactModule";
import SEO from "../Components/Seo/seo";
const AreaguideDetails = (props) => {
    const GQLPage = props.data.glstrapi?.areaguide;
    const GQLModules = props.data.glstrapi?.areaguide?.Modules;
    const metadesc = "Know more about "+GQLPage.Name+" here. Contact one of our estate agents for assistance in finding the right property for you in "+GQLPage.Name+".";
    return (
        <React.Fragment>
        <div id={`body-bg-default`} className={`wrapper areaguide-details`}>
        <div className="home-header"><Header/></div>
        <SEO title={GQLPage.Meta_Title?GQLPage.Meta_Title:GQLPage.Name+' Area Guide'} description={metadesc?metadesc:GQLPage.Meta_Description} />
        {GQLModules.map((Modules, i) => {
            return<>
            <Intro areaguideName={GQLPage.Name} title={Modules.Intro_Title} content={Modules.Intro_Content} ctas={Modules.Intro_CTA} isAreaguidepage={true}/>
            </>
        })}
        <DetailsInfo data={GQLPage}/>
        <Gallery staff={GQLPage.Select_Member} title={GQLPage.Name} galleryimages={GQLPage.Gallery_Images} boximages={GQLPage.Box_Images} belowcontent={GQLPage.Intro_Below_Content} ctas={GQLPage.Bottom_CTA}/>
        {GQLPage.Latitude && GQLPage.Longitude &&
            <DetailMap Latitude={GQLPage.Latitude} Longitude={GQLPage.Longitude}/>
        }
        <FeaturedProperty type="sales" area={GQLPage.Name} title={` in ${GQLPage.Name}`}/>
        <ElfsightWidget elfsightCode={GQLPage.Elfsight_Code} />
        <ContactModule staff={GQLPage.Select_Member}/>
        <Footer/>
        </div>
        </React.Fragment>
    );
};

export default AreaguideDetails;
export const areaQuery = graphql`
query AreaQuery($id: ID!) {
  glstrapi {
    areaguide(id: $id) {    
        id
        Name
        URL
        Meta_Title
        Meta_Description
        Short_Intro
        Youtube_Video_URL        
        Select_Member {
          id
          URL
          Staff_Image {
            url
            alternativeText
          }
          Staff_Image_Detail_Page{
             url
             alternativeText
          }
          imagetransforms
          Name
          Designation
          Phone_Number
        }
        Bottom_CTA {
            CTA_Label
            CTA_Link {
                Primary_URL
            }
            CTA_Custom_Link
        }
        Modules {
          ... on GLSTRAPI_ComponentComponentsIntro {
            __typename
            id
            Intro_Title
            Intro_Content
            Intro_CTA {
              CTA_Custom_Link
              id
              CTA_Link {
                Primary_URL
              }
              CTA_Label
            }
          }
        }
        imagetransforms
        Banner_Image {
          url
          alternativeText
        }
        Gallery_Images {
          Areaguides_Images {
            url
            alternativeText
          }
        }
        Box_Images {
          Areaguide_Box_Image {
            url
            alternativeText
          }
        }
        Intro_Above_Content
        Intro_Below_Content
        Elfsight_Code
        Latitude
        Longitude
        Two_Column_Content {
            Left_Column_Content
            Right_Column_Content
        }
      }
}
}`
