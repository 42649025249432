import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Button, Form, Container, Row, Col, Dropdown } from 'react-bootstrap';
import Img01 from '../../../images/Office/contact-img.jpg';
import ContactImg1400 from '../../../images/Office/contact-img1400.jpg';
import ScrollAnimation from 'react-animate-on-scroll';
import './RightBlock.scss'
import SocialShare from "../../../share";
import GetStaff from "../../get-staff-strapi";
// markup
const AreaRightBlock = (props) => {
  return (
    <React.Fragment>
      <div className="right-block">
        <span class="icons d-none d-lg-block">
        <SocialShare/>
        </span>
        <GetStaff member={props.staff}/>
        {/* <div className="contact-details">        
          <div className="contact-wrapper">
            <div className="contact-img">
              <picture>
                <source media="(min-width:992px)" srcSet={ContactImg1400} />
                <img src={Img01} alt="Img" />
              </picture>
            </div>
            <div className="contact-info ">
              <h3>Richard Burns</h3>
              <span className="profile">Branch manager</span>
              <span>
                <Link to="mailto:mail" className="dark-text">Send email</Link> or <br />call
                <Link to="tel:01708259539" className="tel"> 01708 259539</Link>
              </span>
            </div>

          </div>
          <Link to="#" className="btn btn-primary">Contact Us</Link>

        </div> */}

        <div className="appraisal-bar">
          <div className="left-block">
            <h2>What’s my property worth?</h2>
            <p>
              Selling or Letting? Get a free and up-to-date valuation from one of our experts.
            </p>
          </div>
          <div className="right-block">
            <Link to="/property-valuation/" className="btn">
              Book your market appraisal
            </Link>
          </div>
        </div>
      </div>

    </React.Fragment>
  )
}
export default AreaRightBlock

