import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import PlayVideo from "../../../Components/Play/PlayVideo";
import { Container, Row, Col, Card } from "react-bootstrap";
import ScrollAnimation from 'react-animate-on-scroll';
import "./DetailsInfo.scss";
import AreaRightBlock from "../../PropertyDetails/RightBlock/AreaRightBlock"
import parse from 'html-react-parser';
import { AltSiteName } from "../../utils";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import getVideoId from "get-video-id"
import SocialShare from "../../../share";
import Sticky from 'react-stickynode';
import $ from "jquery";

const DetailsInfo = (props) => {
  const [ClientWidth, setClientWidth] = useState(false);

  useEffect(() => {
    if($(window).width() > 1600 ) {
      setClientWidth(true);
    }
  })

  const [isPlay, setPlay] = useState(false);
  const guideitem = props.data
  let processedImages = JSON.stringify({});
  if (guideitem?.imagetransforms?.Banner_Image_Transforms) {
  processedImages = guideitem.imagetransforms.Banner_Image_Transforms;
  }
  const videoid = guideitem.Youtube_Video_URL && getVideoId(guideitem.Youtube_Video_URL);

  return (
    <React.Fragment>
      <section className="detailsinfo-section">
          <Container>
            <Row>
              <Col lg={8}>
                <div className="details">
                  {parse(guideitem.Short_Intro)}
                  <div className="right-block d-none d-md-block d-lg-none">
                    <span class="icons">
                     <SocialShare/>
                    </span>
                  </div>
                </div>
                <div className="info-img img-zoom">
                <ImageTransform imagesources={guideitem.Banner_Image.url} renderer="srcSet" imagename="areaguides.Banner_Image.commontile" attr={{ alt: guideitem.Banner_Image.alternativeText?guideitem.Banner_Image.alternativeText:guideitem.Name+AltSiteName}} imagetransformresult={processedImages} id={guideitem.id}/>  
                {guideitem.Youtube_Video_URL?
                  <button
                    type="button"
                    className="btn-play"
                    onClick={(e) => {
                      setPlay(true);
                    }}
                  >
                    <i className="icon-play"></i>
                  </button>
                  :""}
                </div>
                {guideitem.Two_Column_Content ?
                <div className="content-wrapper">
                  {parse(guideitem.Two_Column_Content.Left_Column_Content)}

                  {parse(guideitem.Two_Column_Content.Right_Column_Content)}

                </div>
                :""}
                <div className="above-intro">
                  {parse(guideitem.Intro_Above_Content)}
                </div>
              </Col>
              <Col lg={4} className="d-none d-lg-block">
                {ClientWidth ?
                <Sticky top=".header" bottomBoundary=".bottom-ctas">
                <AreaRightBlock staff={guideitem.Select_Member}/>
                </Sticky>
                :
                <AreaRightBlock staff={guideitem.Select_Member}/>
                }
              </Col>
            </Row>
          </Container>
      </section>
      {videoid &&
      <PlayVideo
        isOpen={isPlay}
        isCloseFunction={setPlay}
        videoId={videoid.id}
        isAutoPlay={1}
      />
      }
    </React.Fragment>
  );
};
export default DetailsInfo;
